import { Slug } from "~/utilities/enums/Slug";

const COMPANY_MENU = [
  {
    label: "footer.company_menu.about_us",
    href: Slug.ABOUT_US,
  },
  {
    label: "footer.company_menu.contact_us",
    href: Slug.CONTACT_US,
  },
];

const SEGMENT_MENU = [
  {
    label: "footer.segment_menu.RCR",
    href: `${Slug.PROJECTS}?marketSegment=RCR`,
  },
  {
    label: "footer.segment_menu.CCR",
    href: `${Slug.PROJECTS}?marketSegment=CCR`,
  },
  {
    label: "footer.segment_menu.OCR",
    href: `${Slug.PROJECTS}?marketSegment=OCR`,
  },
];

export { COMPANY_MENU, SEGMENT_MENU };
