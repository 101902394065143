import pageConfig from "./pageConfig";
import { Slug } from "../enums/Slug";

import { IMenuItem } from "~/types";

export const MenuItems: IMenuItem[] = [
  {
    href: Slug.VIRTUAL_TOURS,
    title: pageConfig[Slug.VIRTUAL_TOURS]?.title ?? "",
  },
  {
    href: Slug.MORTGAGE_CALCULATOR,
    title: pageConfig[Slug.MORTGAGE_CALCULATOR]?.title ?? "",
  },
  {
    href: Slug.LATEST_MORTGAGE_RATES,
    title: pageConfig[Slug.LATEST_MORTGAGE_RATES]?.title ?? "",
  },
];

export const MenuItemsMobile: IMenuItem[] = [
  {
    href: Slug.PROJECTS,
    title: pageConfig[Slug.PROJECTS]?.title ?? "",
  },
  {
    href: Slug.VIRTUAL_TOURS,
    title: pageConfig[Slug.VIRTUAL_TOURS]?.title ?? "",
  },
  {
    href: Slug.MORTGAGE_CALCULATOR,
    title: pageConfig[Slug.MORTGAGE_CALCULATOR]?.title ?? "",
  },
  {
    href: Slug.LATEST_MORTGAGE_RATES,
    title: pageConfig[Slug.LATEST_MORTGAGE_RATES]?.title ?? "",
  },
];
